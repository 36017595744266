<template>
  <div v-cloak>
    <LoadingComponent v-if="!apiLoaded" :text="loadingText"></LoadingComponent>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap archive">
          <a @click="$router.go(-1)" class="back-link">
            <v-icon class="icon-item icon-chevron-left"></v-icon>
            Назад
          </a>
          <div class="header block">
            <div class="header-passport">
              <h1>
                Архив документов
              </h1>
              <v-autocomplete
                  dense
                  label="Регион"
                  :items="regionList"
                  item-text="name"
                  return-object
                  v-model="userRegion"
                  @input="initialize();"
              ></v-autocomplete>
            </div>
          </div>

          <div class="block">
            <h3>Фильтры:</h3>

            <v-autocomplete
                dense
                label="Фильтр по ЦЗН"
                v-model="cznFilter"
                :items="departments"
                item-text="name"
                return-object
                @change="$forceUpdate()"
                clearable
            ></v-autocomplete>

            <v-autocomplete
                dense
                label="Фильтр по разделам"
                v-model="categoryFilter"
                :items="getDocumentCategories()"
                @change="$forceUpdate()"
                clearable
            ></v-autocomplete>
          </div>

          <div class="docs-table">
            <table>
              <tr>
                <th>ДОКУМЕНТ</th>
                <th>ЦЗН</th>
                <th>Раздел</th>
                <th>Категория</th>
                <th>Дата загрузки</th>
                <th>Статус</th>

              </tr>
              <tr v-for="document in filterDocuments()" :key="'doc'+document.file.id">
                <td>
                  <a class="docs-attach" @click="downloadFile(document.file.id, document.file.name)">{{truncateStr(document.file.name, 30)}}</a>
                </td>
                <td>
                  {{ document.department == null ? 'Все' : document.department.name }}
                </td>
                <td>
                  {{ document.section }}
                </td>
                <td>
                  {{ document.category }}
                </td>
                <td>{{ document.file.createdDateTime | dateFormat }}</td>
                <td>{{ document.deleted ? 'Удален' : 'Действующий' }}</td>
              </tr>
            </table>
          </div>
        </div>

        <div
            v-if="errorText != null"
            class="error"
            style="position: fixed; bottom: 0"
        >
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height: 1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<script>
import LoadingComponent from "@/components/elements/LoadingComponent";
import api from "@/modules/api";

export default {
  name: "passportArchive",
  props: {},
  components: {
    LoadingComponent,
  },
  data() {
    return {
      apiLoaded: false,
      userRegion: {},
      regionList: [],
      departments: [],
      errorText: null,
      loadingText: [],
      documents: [],
      categoryFilter: null,
      cznFilter: null
    };
  },
  methods: {
    truncateStr(str, n) {
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },

    getDocumentCategories(){
      const categories = []
      for(let doc of this.documents){
        if(doc.category && !categories.includes(doc.category)){
          categories.push(doc.category)
        }
      }
      return categories
    },

    async downloadFile(fileId, fileName) {
      let req = await api.download("/s3/" + fileId, {});
      if (req.ok) {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(req.payload);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        console.log('download error');
      }
    },

    filterCzns(){
      this.documents = this.documents.filter(e=>{
        return e.department == null
            || e.department.id === this.$user.department.id
      })
    },

    async initialize() {
      this.departments = this.userRegion?.departments
      let req = await api.get('passport/getDocuments/' + this.userRegion.code)
      if(req.ok){
        this.documents = req.payload
        if(this.$user.role==='ROLE_CZN_MANAGER' || this.$user.role==='ROLE_CZN_TERRITORIAL'){
          this.filterCzns()
        }
      } else {
        alert('Ошибка загрузки данных')
      }
      this.$forceUpdate()
    },

    getRegionByCode(regionCode) {
      for (let region of this.regionList) {
        if (region.code === regionCode) {
          return region
        }
      }
    },

    getDepartments(regionCode) {
      for (let region of this.regionList) {
        if (region.code === regionCode) {
          this.departments = region.departments
        }
      }
    },

    filterDeps(name) {
      return name.toUpperCase().includes(this.depsFilter.toUpperCase())
    },

    filterDocuments(){
      let filteredDocs = this.documents
      if(this.cznFilter){
        filteredDocs = filteredDocs.filter(e=>e.department?.id === this.cznFilter.id)
      }
      if(this.categoryFilter){
        filteredDocs = filteredDocs.filter(e=>e.category === this.categoryFilter)
      }
      return filteredDocs
    }
  },

  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    },
  },

  async beforeMount() {
    this.regionList = this.$cznDepartmentsByRegionList;
    this.userRegion = this.regionList[0];

    await this.initialize();
    this.loadingText.splice(this.loadingText.length - 1, 1)
    this.loadingText.push('Загружены данные паспорта региона')
    this.apiLoaded = true;
  }
};
</script>


<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
.subheader {
  font-size: 17px;
  color: #1C1C1F;
  font-weight: 600;
}

.additional-info {
  div {
    display: inline-block;
    min-width: 65px;
    font-size: 13px;
    font-weight: 500;
    color: #777779;
  }

  .v-icon {
    display: inline-block;
    font-size: 14px;
    color: #777779;
  }

  .v-icon:nth-child(2) {
    margin-left: 5px;
  }
}
</style>
